.videoRow{
    display: flex;
    margin-bottom: 30px;
    max-width: 700px;
}

.videoRow > img{
    object-fit: contain;
    width: 246px;
    height: 138px;
}

.videoRow__text{
    margin-left: 14px;
}

.videoRow__subs{
    background-color: lightgray;
    padding: 2px;
}

.videoRow__headline {
    font-size: 12px;
    color: #606060;
}

.videoRow__description {
margin-top: 20px;
font-size: 12px;
color: #606060;
}

.videoRow__subsNumber {
font-weight: bolder;
color: rgb(84, 157, 211);
}