.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
}

.header__logo {
    height: 50px;
    object-fit: contain;
    margin-left: 20px;
}

.header__input{
    display: flex;
    align-items: center;
    width: 40%;
    border: 1px solid lightgray;
}

.header__icons{
    display: flex;
    align-items: center;
}

.header__inputButton {
    width: 50px !important;
    background-color: #fafafa;
    border-left: 1px solid lightgray;
    color: gray;
}

.header__input > input{
    flex: 1;
    border: none;
}

.header__icon{
    margin-right: 8px;
}