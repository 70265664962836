.sidebarRow {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.sidebarRow__icon {
  color: #606060;
  font-size: large !important;
}

.sidebarRow__title {
    flex: 1;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 500;
}

.sidebarRow:hover{
    background-color: lightgray;
    cursor: pointer;
}

.sidebarRow:hover > .sidebarRow__icon{
    color: red;
}

.sidebarRow:hover > .sidebarRow__title{
    font-weight: bold;
}

.sidebarRow.selected {
    background-color: lightgray;
}

.sidebarRow.selected > .sidebarRow__icon {
    color: red;
}

.sidebarRow.selected > .sidebarRow__title {
    font-weight: bold;
}